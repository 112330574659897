import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import PageLayout from '@/components/layout/PageLayout';

function Home() {
  const { t } = useTranslation();

  return (
    <PageLayout title={t('pageTitle.start')}>
      <Typography>Admin Frontend</Typography>
    </PageLayout>
  );
}

export default Home;
