import { Container, Divider } from '@mui/material';
import React from 'react';

function Footer() {
  return (
    <Container sx={{ pb: { xs: 3, lg: 24 }, pt: 13 }} component="footer">
      <Divider />
    </Container>
  );
}

export default Footer;
