import RouterLink from 'next/link';
import { ListItemIcon, ListItemText, List, ListItemButton } from '@mui/material';
import { useRouter } from 'next/router';

import { mainNavList } from '@/constants';
import { useStore } from '@/stores';

function MainNavList() {
  const router = useRouter();
  const { AccountStore: { isLoggedIn } } = useStore();

  return (
    <List dense component="nav" aria-label="main">
      {mainNavList.filter(({ needsAuth }) => needsAuth && isLoggedIn || !needsAuth && !isLoggedIn).map((item) => (
        <RouterLink key={item.route} href={item.route} passHref legacyBehavior>
          <ListItemButton component="a" selected={item.route === router.pathname}>
            <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </ListItemButton>
        </RouterLink>
      ))}
    </List>
  );
}

export default MainNavList;
