function Logo() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM21.2829 8H18.5838V13.1033C13.3798 13.4848 9.66667 16.5002 9.66667 21.7128V29.6667H12.3658V21.7995C12.3658 18.145 14.5635 16.123 18.5838 15.7079V29.6378H21.2829V15.7079C25.3031 16.123 27.5008 18.145 27.5008 21.7995V29.6667H30.2V21.7128C30.2 16.5002 26.4869 13.4848 21.2829 13.1033V8Z"
        fill="#00DFA3"
      />
    </svg>
  );
}

export default Logo;
