import { Box, Breakpoint, Container } from '@mui/material';
import React, { ReactNode } from 'react';
import Head from 'next/head';

import HeadAlerts from '@/components/notification/HeadAlerts';
import MainNavbar from '@/components/navigation/MainNavbar';
import { UtilitiesService } from '@/services';
import LoadingScreen from '@/components/shared/LoadingScreen';
import { MenuSizes } from '@/types';

import Footer from './Footer';

type Props = {
  children: ReactNode;
  maxWidth?: Breakpoint;
  noContainer?: boolean;
  noNavbarContainer?: boolean;
  noFooter?: boolean;
  title: string;
  loading?: boolean;
  menuSize?: MenuSizes;
};

function PageLayout({
  children,
  maxWidth = undefined,
  noContainer = false,
  noNavbarContainer = false,
  noFooter = false,
  title,
  loading,
  menuSize,
}: Props) {
  let body: ReactNode | null;
  if (noContainer) {
    body = children;
  } else {
    body = (
      <Container maxWidth={maxWidth} sx={{ my: 4 }}>
        <HeadAlerts />
        {children}
      </Container>
    );
  }

  return (
    <Box
      sx={{
        minHeight: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Head>
        <title>{UtilitiesService.getPageTitle(title)}</title>
        <meta name="description" content="" />
      </Head>
      <MainNavbar menuSize={menuSize} noContainer={noNavbarContainer} />
      <Box component="main" sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        {loading ? <LoadingScreen /> : body}
        {!noFooter && <Footer />}
      </Box>
    </Box>
  );
}

PageLayout.defaultProps = {
  loading: false,
  maxWidth: undefined,
  noContainer: false,
  noNavbarContainer: false,
  noFooter: false,
  menuSize: undefined,
};

export default PageLayout;
