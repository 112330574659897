import RouterLink from 'next/link';
import { Dashboard, Settings } from '@mui/icons-material';
import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ErrorService } from '@/services';
import { useStore } from '@/stores';

function AccountPopover() {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const {
    AccountStore,
    AccountStore: { currentAccount },
  } = useStore();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await AccountStore.logoutCurrentUser();
    } catch (error) {
      ErrorService.handleError(error);
    }
  };

  if (!currentAccount) return <LinearProgress />;
  const { email, firstName, lastName } = currentAccount;
  const fullName = `${firstName} ${lastName}`;

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          src={undefined}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        // getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" sx={{ fontWeight: 600 }}>
            {fullName}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <Typography color="textPrimary" noWrap>
              {email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <RouterLink href="/dashboard" passHref legacyBehavior>
            <MenuItem component="a" onClick={handleClose}>
              <ListItemIcon>
                <Dashboard fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<Typography color="textPrimary">{t('nav.dashboard')}</Typography>} />
            </MenuItem>
          </RouterLink>
          <Divider />
          <RouterLink href="/account" passHref legacyBehavior>
            <MenuItem component="a" onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<Typography color="textPrimary">{t('nav.account')}</Typography>} />
            </MenuItem>
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            {t('generic.logout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default observer(AccountPopover);
