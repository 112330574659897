import RouterLink from 'next/link';
import {
  Box,
  Button,
  Divider,
  ListItemText,
  List,
  Typography,
  ListItemButton,
  Link,
  Dialog,
  useMediaQuery,
  Theme,
  Toolbar,
  IconButton,
  Paper,
} from '@mui/material';
import type { MouseEvent } from 'react';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

import Logo from '@/components/shared/Logo';
import { mainNavList } from '@/constants';
import { MenuSizes } from '@/types';
import MainNavList from '@/components/navigation/MainNavList';
import { useStore } from '@/stores';

type Props = {
  menuSize?: MenuSizes;
};

function MainNav({ menuSize }: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const { AccountStore } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const { isLoggedIn } = AccountStore;
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCurrentItem = () => {
    const findCurrent = mainNavList.find((item) => item.route === router.pathname);

    if (!findCurrent) return { label: t('generic.menu') };

    return findCurrent;
  };

  return (
    <Box
      sx={{
        flex: '1 0',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        mr: { md: 2 },
      }}
    >
      <RouterLink href="/" passHref legacyBehavior>
        {/* TODO: Fix all of these after a fix has been released. Follow along: https://github.com/vercel/next.js/issues/5533 */}
        <Link href="to-be-replaced" sx={{ mr: 3 }}>
          <Logo />
        </Link>
      </RouterLink>
      {menuSize === 'small' || !isDesktop ? (
        <>
          <Button
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              color: 'text.primary',
              textTransform: 'unset',
            }}
          >
            <Typography>{getCurrentItem().label}</Typography>
            <ArrowDropDownIcon />
          </Button>
          <Dialog fullScreen open={open} onClose={handleClose}>
            <Paper square sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </Paper>
            <MainNavList />
            <Divider />
            {!isLoggedIn && (
              <List dense component="nav" aria-label="account">
                <RouterLink href="/login" passHref legacyBehavior>
                  <ListItemButton component="a" selected={router.pathname === '/login'}>
                    <ListItemText>{t('nav.login')}</ListItemText>
                  </ListItemButton>
                </RouterLink>
              </List>
            )}
          </Dialog>
        </>
      ) : (
        mainNavList.filter(({ needsAuth }) => needsAuth && isLoggedIn || !needsAuth && !isLoggedIn).map((item) => (
          <RouterLink key={item.route} href={item.route} passHref legacyBehavior>
            <Link
              href="to-be-replaced"
              underline="none"
              sx={{ mx: 0.5, p: 1, color: 'text.primary', fontWeight: 'fontWeightMedium' }}
            >
              {item.label}
            </Link>
          </RouterLink>
        ))
      )}
    </Box>
  );
}

MainNav.defaultProps = {
  menuSize: undefined,
};

export default MainNav;
