import RouterLink from 'next/link';
import { observer } from 'mobx-react';
import { AppBar, Box, Link, Theme, Toolbar, useMediaQuery, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStore } from '@/stores';
import AccountPopover from '@/components/account/AccountPopover';
import { MenuSizes } from '@/types';

import MainNav from './MainNav';

type Props = {
  menuSize?: MenuSizes;
  noContainer?: boolean;
};

function MainNavbar({ menuSize, noContainer }: Props) {
  const {
    AccountStore: { isLoggedIn },
  } = useStore();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const body = (
    <Toolbar sx={{ minHeight: 64, px: '0 !important' }}>
      <MainNav menuSize={menuSize} />
      {/* TODO: is there a better way to get portal reference? */}
      <Box
        id="mainNavPortal"
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      />
      {(isLoggedIn || isDesktop) && (
        <Box
          sx={{
            flex: { md: '1 0' },
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            ml: {
              xs: 1,
              md: 2,
            },
          }}
        >
          {isLoggedIn ? (
            <AccountPopover />
          ) : (
            <RouterLink href="/login" passHref legacyBehavior>
              <Link href="to-be-replaced" color="textSecondary" variant="body">
                {t('nav.login')}
              </Link>
            </RouterLink>
          )}
        </Box>
      )}
    </Toolbar>
  );

  const content = noContainer ? <Box sx={{ px: 3 }}>{body}</Box> : <Container>{body}</Container>;

  return (
    <>
      <AppBar
        elevation={0}
        square
        sx={{
          top: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: 'text.secondary',
          backgroundColor: 'background.paper',
          borderBottom: 1,
          borderColor: 'border.main',
        }}
      >
        {content}
      </AppBar>
      <Box sx={{ height: '64px' }} /> {/* Placeholder preventing the content to hide behind AppBar */}
    </>
  );
}

MainNavbar.defaultProps = {
  menuSize: undefined,
  noContainer: false,
};

export default observer(MainNavbar);
