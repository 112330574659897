import { Alert, AlertColor } from '@mui/material';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

function HeadAlerts() {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    query: { message, result },
  } = router as { query: { message?: string; result?: AlertColor } };
  const showUrlAlert =
    message && result && (result === 'info' || result === 'success' || result === 'warning' || result === 'error');

  if (!showUrlAlert) {
    return null;
  }

  if (showUrlAlert && message) {
    return (
      <Alert severity={result} sx={{ mb: 1 }}>
        {t(message)}
      </Alert>
    );
  }

  return null;
}

export default observer(HeadAlerts);
